/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Taxonomy } from "../../../models/taxonomy/taxonomy";
import store from "@/store/index";
Vue.use(Vuex);

function compare (a, b) {
  const taxonomyAName = a.Taxonomy.DisplayName.toUpperCase();
  const taxonomyBName = b.Taxonomy.DisplayName.toUpperCase();
  const taxonomyAEncounter = a.Taxonomy.IsCurrentEncounter;
  const taxonomyBEncounter = b.Taxonomy.IsCurrentEncounter;
  const taxonomyAPrincipal = a.Taxonomy.IsPrincipalProblem;
  const taxonomyBPrincipal = b.Taxonomy.IsPrincipalProblem;

  if (taxonomyAPrincipal !== taxonomyBPrincipal) {
    return taxonomyBPrincipal - taxonomyAPrincipal;
  } else if (taxonomyAEncounter !== taxonomyBEncounter) {
    return taxonomyBEncounter - taxonomyAEncounter;
  } else {
    return taxonomyAName.localeCompare(taxonomyBName);
  }
}

@Module({ name: "Taxonomies", namespaced: true, dynamic: true, store })
class Taxonomies extends VuexModule {
  taxonomies = [] as Taxonomy[];

  get getTaxonomies () {
    if (this.taxonomies) {
      return this.taxonomies.sort(compare);
    } else { return []; }
  }

  get getTaxonomyByCode () {
    return function (this: Taxonomies, taxonomyCode: string): Taxonomy | undefined {
      return this.taxonomies?.find(x => x.Taxonomy.Codings[0].Code === taxonomyCode);
    };
  }

  @Mutation
  SET_TAXONOMIES (taxonomies: Taxonomy []) {
    // if there is already a taxonomy, add the new ones to the existing ones
    // if there is nothing in the main taxonomy array, set the new ones
    // otherwise, don't do anything
    if (this.taxonomies.length > 0 && taxonomies.length > 0) {
      // check if taxonomy doesn't exist in the main array before adding it
      taxonomies.forEach(taxonomy => {
        if (!this.taxonomies.find(x => x.Taxonomy.Code === taxonomy.Taxonomy.Code)) {
          this.taxonomies.push(taxonomy);
        }
      });
    }
    else if (this.taxonomies.length === 0) { 
      this.taxonomies = taxonomies;
    }
  }

  @Action
  setTaxonomies (taxonomies: Taxonomy []) {
    if (!taxonomies) taxonomies = [];
    this.SET_TAXONOMIES(taxonomies);
  }

  @Action
  clearTaxonomies () {
    this.SET_TAXONOMIES([]);
  }

}
export default getModule(Taxonomies);
