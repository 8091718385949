
import { defineComponent } from "vue";
const ListItemLoader = () => import("./ListItemLoader.vue");
export default defineComponent({
  name: "InitialLoader",
  components: {
    ListItemLoader,
  },
  setup () {
    const types = {
      categories: "button@3",
      toggleRow: "text",
    };

    return { types };
  },
});
