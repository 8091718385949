/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { UIConfigurationModel, TypeaheadConfiguration } from "../../../models/uiConfiguration/uiConfiguration";
import store from "@/store/index";
Vue.use(Vuex);

@Module({ name: "UIConfiguration", namespaced: true, dynamic: true, store })
class UIConfiguration extends VuexModule {
  uiConfiguration = {} as UIConfigurationModel;
  cartLimitExceededDialogVisibility = false;
  typeaheadConfiguration = {} as TypeaheadConfiguration;

  get getUIConfiguration () {
    return this.uiConfiguration;
  }

  get getTypeaheadConfiguration () {
    return this.typeaheadConfiguration;
  }

  get getCartLimitExceededDialogVisibility () {
    return this.cartLimitExceededDialogVisibility;
  }

  get getAvsRecallInfo () {
    const AvsRecallEnabled = this.uiConfiguration.AvsRecallEnabled;
    const AvsRecallTime = this.uiConfiguration.AvsRecallTime;
    return { AvsRecallEnabled, AvsRecallTime };
  }

  @Mutation
  SET_UICONFIGURATION (uiConfigurationInfo: UIConfigurationModel) {
    this.uiConfiguration = uiConfigurationInfo;
  }

  @Action
  setTypeaheadConfiguration (typeaheadConfiguration) {
    this.SET_TYPEAHEADCONFIGURATION(typeaheadConfiguration);
  };

  @Mutation
  SET_TYPEAHEADCONFIGURATION (typeaheadConfiguration) {
    this.typeaheadConfiguration = typeaheadConfiguration;
  }

  @Mutation
  SET_CARTLIMITEXCEEDEDDIALOGVISIBILITY (flag: boolean) {
    this.cartLimitExceededDialogVisibility = flag;
  }

  @Action
  setUIConfiguration (uiConfigurationInfo: UIConfigurationModel) {
    this.SET_UICONFIGURATION(uiConfigurationInfo);
  }

  @Action setCartLimitExceededDialogVisibility (flag: boolean) {
    this.SET_CARTLIMITEXCEEDEDDIALOGVISIBILITY(flag);
  }
}

export default getModule(UIConfiguration);
