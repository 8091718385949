import { ContentList } from "@/models/content/content";

function mapContentToFavorite (contentItem: ContentList) {
  return {
    ContentId: contentItem.ContentId,
    ContentTypeId: contentItem.ContentTypeId,
    FrontEndId: contentItem.FrontEndId,
    ContentItem: contentItem,
  };
}

export default mapContentToFavorite;
