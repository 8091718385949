import { ref, reactive } from "vue";

import HelpService from "@/services/helpService";

import HelpModule from "@/store/modules/Help/help";

const isHelpLoading = ref(false);
const isHelpDialogVisible = reactive({ isVisible: false });
const getHelpContent = () => {
  if (HelpModule.getContent) {
    return HelpModule.getContent;
  }
  return HelpService.getHelpContent().then(res => {
    HelpModule.setHelpContent(res);
  });
};

const openHelpDialog = async () => {
  isHelpDialogVisible.isVisible = true;
  isHelpLoading.value = true;
  await getHelpContent();
  isHelpLoading.value = false;
};

const useHelpDialog = () => {
  return { isHelpLoading, isHelpDialogVisible, openHelpDialog };
};

export { useHelpDialog };
