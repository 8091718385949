
import { defineComponent, ref, watch, computed, WritableComputedRef } from "vue";
import TableSettingsModule from "../store/modules/TableSettings/tableSettings";
import ContentModule from "../store/modules/Content/content";
import UserModule from "../store/modules/User/user";
import HistoryModule from "../store/modules/History/history";

export default defineComponent({
  name: "TableControls",
  setup () {
    const isFolderView = computed(() => TableSettingsModule.viewingFolders);
    const isHistoryView = computed(() => TableSettingsModule.viewingHistory);
    const isFavoritesView = computed(() => TableSettingsModule.viewingFavorites);
    const isGridView = computed(() => UserModule.isGridView);
    const rowsPerPageItems = computed(() => TableSettingsModule.getRowsPerPageType);
    const rowsPerPage = computed(() => TableSettingsModule.getRowsPerPage);

    const pageNumber: WritableComputedRef<number> = computed({
      get (): number {
        return TableSettingsModule.pageNumber;
      },
      set (newValue: number): void {
        TableSettingsModule.updatePageNumber(newValue);
      },
    });

    const selectedRowsPerPage = ref(rowsPerPage.value);
    const updateTableView = (view) => {
      TableSettingsModule.setTableView(view);
      selectedRowsPerPage.value = rowsPerPage.value;
    };

    const changeResultsPerPage = (newValue) => {
      if (UserModule.isGridView) {
        UserModule.updateCardsPerPage(newValue);
      } else {
        UserModule.updateRowsPerPage(newValue);
      }
    };

    watch(rowsPerPage, () => {
      selectedRowsPerPage.value = rowsPerPage.value;
    });

    watch(isGridView, (newVal) => {
      if (newVal) {
        updateTableView("grid");
      } else {
        updateTableView("list");
      }
    });

    watch(isHistoryView, (newVal) => {
      if (newVal) {
        updateTableView("list");
      } else if (isGridView.value) {
        updateTableView("grid");
      }
    });

    watch(selectedRowsPerPage, (newValue) => {
      TableSettingsModule.updateSelectedRowsPerPage(newValue);
      /*
        wait a very small amount before updating page number, so that watchers
        in other files work correctly (like patient encounter)
      */
      setTimeout(() => TableSettingsModule.updatePageNumber(1), 250);
    });

    const totalItems = computed(() => {
      let itemCount = 0;
      if (isHistoryView.value) {
        itemCount = HistoryModule.displayedContentCount;
      } else if (isFavoritesView.value) {
        itemCount = ContentModule.favoritesDisplayedContentCount;
      } else {
        itemCount = ContentModule.displayedContentCount;
      }
      return itemCount;
    });

    const getPaginationString = function(this: any) {
      // Explicitly cast to number and handle potential NaN values
      const rowsPerPage = Number(this.selectedRowsPerPage);
      const page = Number(this.pageNumber);

      if (isNaN(rowsPerPage) || isNaN(page)) {
        // Invalid pagination values
        return `Undefined`;
      }

      const start = (rowsPerPage * (page - 1)) + 1;
      return `${start}-${Math.min(start + rowsPerPage - 1, this.totalItems)} of ${this.totalItems}`;
    };

    return {
      changeResultsPerPage,
      selectedRowsPerPage,
      rowsPerPageItems,
      rowsPerPage,
      pageNumber,
      totalItems,
      isGridView,
      isFolderView,
      getPaginationString,
    };
  },
});
