import axios from "axios";
class PrintService {
  async print (cart) {
    return await axios
      .post("api/print", cart)
      .then(r => r.data)
      .catch(e => console.log(e));
  }

  async share (cart) {
    return await axios
      .post("api/publish", cart)
      .then(r => r.data)
      .catch(e => console.log(e));
  }
}

export default new PrintService();
