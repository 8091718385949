/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store/index";
Vue.use(Vuex);

@Module({ name: "SearchBar", namespaced: true, dynamic: true, store })
class SearchBar extends VuexModule {
  searchTerm = "";
  selectedSearchTerm: string|undefined = undefined;
  typedTerms: string[] = [];
  isFilterOpen = false;
  searchResultsVisible = false;
  isSearching = false;
  searchTerms = [] as string[];
  isReloadContent = false;

  get getSearchTerms () {
    return this.searchTerms;
  }

  get getSelectedSearchTerm () {
    return this.selectedSearchTerm;
  }

  get getTypedTerms () {
    return this.typedTerms;
  }

  @Action
  updateSearchTerms (terms) {
    this.SET_SEARCH_TERMS(terms);
  }

  @Mutation
  SET_SEARCH_TERMS (terms) {
    this.searchTerms = terms;
  }

  @Action
  updateSelectedSearchTerm (terms) {
    this.SET_SELECTED_SEARCH_TERMS(terms);
  }

  @Mutation
  SET_SELECTED_SEARCH_TERMS (terms) {
    this.selectedSearchTerm = terms;
  }

  @Action
  updateTypedTerms (terms) {
    this.SET_TYPED_TERMS(terms);
  }

  @Mutation
  SET_TYPED_TERMS (terms) {
    this.typedTerms = terms;
  }

  @Action
  setReloadContent (value: boolean) {
    this.SET_IS_RELOADCONTENT(value);
  }

  @Mutation
  SET_IS_RELOADCONTENT (value: boolean) {
    this.isReloadContent = value;
  }

  @Action
  doSearch (value: boolean) {
    this.SET_IS_SEARCHING(value);
  }

  @Mutation
  SET_IS_SEARCHING (value: boolean) {
    this.isSearching = value;
  }

  @Action
  updateSearchTerm (term: string) {
    this.SET_SEARCHTERM(term);
  }

  @Mutation
  SET_SEARCHTERM (term: string) {
    this.searchTerm = term;
  }

  @Action
  updateFilterStatus (open: boolean) {
    this.SET_FILTER_STATUS(open);
  }

  @Mutation
  SET_FILTER_STATUS (open: boolean) {
    this.isFilterOpen = open;
  }

  @Action
  updateResultsVisible (visible: boolean) {
    this.SET_RESULTS_VISIBLE(visible);
  }

  @Mutation
  SET_RESULTS_VISIBLE (visible: boolean) {
    this.searchResultsVisible = visible;
  }

}

export default getModule(SearchBar);
