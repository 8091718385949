import axios from "axios";

class PrivacyPolicyService {
  async getExternalData () {
    return await axios
      .get(`api/ExternalPage/GetPage/Privacy`)
      .then(r => r.data.pageContent)
      .catch(e => console.log(e));
  }
}

export default new PrivacyPolicyService();
