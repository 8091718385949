import { User, UserSettings } from "@/models/user/user";
import axios from "axios";

class UserService {
  async getUserSettings (): Promise<User> {
    return await axios
      .get("api/userSettings")
      .then(r => r.data)
      .catch(e => console.log(e));
  }

  async updateUserSettings (userSettings: UserSettings): Promise<User> {
    return await axios
      .put("api/userSettings", userSettings)
      .then(r => r.data)
      .catch(e => console.log(e));
  }
}

export default new UserService();
