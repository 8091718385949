async function printContent (content) {
  const main = document.getElementById("main");
  const frame = document.createElement("iframe");
  frame.id = "printFrame";
  frame.name = "printFrame";
  const style = document.createElement("link");
  style.href = "/css/Healthsheet/Print.css";
  style.rel = "stylesheet";
  style.media = "print";
  main.appendChild(frame);

  frame.contentWindow.document.write("");
  frame.contentWindow.document.write(content);
  frame.contentWindow.document.head.appendChild(style);
  const tables = frame.contentWindow.document.getElementsByTagName("table");
  const tablesList = Array.prototype.slice.call(tables);
  for (const table of tablesList) {
    const tableCells = table.getElementsByTagName("td");
    const tableCellsList = Array.prototype.slice.call(tableCells);
    for (const cell of tableCellsList) {
      if (cell.getElementsByTagName("img").length !== 0) {
        table.style.breakInside = "avoid";
      }
    }
  }
  setTimeout(function () {
    try {
      frame.contentWindow.document.execCommand("print", false, null);
      main.removeChild(frame);
    } catch (ex) {
      frame.contentWindow.print();
      main.removeChild(frame);
    }
  }, 500);
}

export default printContent;
