/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store/index";
Vue.use(Vuex);

@Module({ name: "Preview", namespaced: true, dynamic: true, store })
class Preview extends VuexModule {
  videoContent = {} as string;
  editableContent = {} as Element;
  metadataContent = {} as Element;
  isPreviewOpen = false;

  get getContent () {
    const html = document.createElement("html");

    html.innerHTML = this.metadataContent.innerHTML;

    const hSBody = html.querySelector("#HSBody") ?? document.createElement("div");
    hSBody.innerHTML = this.editableContent.innerHTML;

    return html.outerHTML;
  }

  get getEditableContent () {
    return this.editableContent.innerHTML;
  }

  @Mutation 
  SET_PREVIEW_OPEN (open: boolean) {
    this.isPreviewOpen = open;
  }

  @Mutation
  SET_PREVIEW_CONTENT (content: string) {
    this.videoContent = content;
    const html = document.createElement("html");
    html.innerHTML = content;
    const hSBody = html.querySelector("#HSBody") ?? document.createElement("div");
    const editableContent = document.createElement("div");

    editableContent.innerHTML = hSBody.innerHTML;

    hSBody.innerHTML = "";

    this.metadataContent = html;
    this.editableContent = editableContent;
  }

  @Mutation
  SET_EDITABLE_CONTENT (content: string) {
    this.editableContent.innerHTML = content;
  }

  @Action
  setPreviewContent (content: string) {
    this.SET_PREVIEW_CONTENT(content);
  }

  @Action
  setEditableContent (content: string) {
    this.SET_EDITABLE_CONTENT(content);
  }

  @Action
  setIsPreviewOpen (open: boolean) {
    this.SET_PREVIEW_OPEN(open);
  }
}

export default getModule(Preview);
