import axios from "axios";
class ContentService {
  async getSuggestedContent (contentRequest) {
    return await axios
      .post("api/getContentForCurrentPatient", contentRequest)
      .then(r => r.data)
      .catch(e => console.log(e));
  }

  async getContentCountsForTaxonomy (contentRequests) {
    return await axios
      .post("api/getContentCountsForTaxonomies", contentRequests)
      .then(r => r.data)
      .catch(e => console.log(e));
  }

  async getTaxonomyResults (refresh = false, categories: string[] = []) {
    return await axios
      .post(`api/getTaxonomiesForCurrentPatient/${refresh}`, categories)
      .then(r => r.data)
      .catch(e => console.log(e));
  }

  async getContentTypes (data = {}) {
    return await axios
      .get("api/contentTypes", data)
      .then(r => r.data)
      .catch(e => console.log(e));
  }
}

export default new ContentService();
