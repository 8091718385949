import axios from "axios";

class EducationCartService {
  async getEducationCart () {
    return await axios
      .get("api/educationCart")
      .then(r => r.data)
      .catch(e => console.log(e));
  }

  async updateEducationCart (educationCart) {
    return await axios
      .put("api/educationCart", educationCart)
      .then(r => r.data)
      .catch(e => console.log(e));
  }
}

export default new EducationCartService();
