import axios from "axios";
class HelpService {
  async getHelpContent () {
    return await axios
      .get(`api/help`)
      .then(r => r.data)
      .catch(e => console.log(e));
  }
}

export default new HelpService();
