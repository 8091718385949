import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        trueBlue: "#0026ca",
        primaryBtn: "#0026ca",
        secondaryBtn: "#0026ca",
        primary: "#161f27",
        secondary: "#222c3c",
        greyBlue: "#637387",
        blueyGray: "#8593a8",
        primaryBg: "#ffffff",
        outlines: "#c2cad7",
        secondaryBg: "#eff1f3",
        accentBg: "#eef5ff",
        success: "#01b788",
        warning: "#fbc02d",
        error: "#eb0052",
        restoreBanner: "#6d3ff3",
        cerulean: "#0095da",
        reddishOrange: "#ec6424",
        paleViolet: "#C2ADFE",
        powderBlue: "#B9D7FB",
        blushPink: "#ff868a",
        midnightBlue: "#191970",
        grayBorder: "#eff1f3",
        anchorTag: "#0026ca",
        errorContainer: "#eaedf1",
        navItem: "#222c3c",
        selectedNavItem: "#0026ca",
        selectedGridItem: "#0026ca",
        selectedTab: "#0023ca",
        subText: "#768496",
        tooltipBg: "#222c3c",
        helpText: "#161f27",
        subHeading: "#8593a8",
        focusedInput: "#161f27",
        folderBreadcrumbs: "#0026ca",
        pageTitleText: "#222c3c",
        accentText: "#637387",
        previewDialogHeading1: "#ec6424",
        previewDialogHeading2: "#0e76bc",
        previewDialogText: "#161f27",
        editIcon: "#222c3c",
        emailNotificationLabelError: "#eb0052",
        emailNotifcationMailIcon: "#C2ADFE",
        tinyMceFontColor: "#161f27",
        tinyMceBgColor: "#f0f0f0",
        documentCaption: "#000000",
      },
      dark: {
        primary: "#346999",
        primaryBtn: "#5A536A",
        secondaryBtn: "#B9B6B6",
        trueBlue: "#121b40",
        secondary: "#6d8dbf",
        primaryBg: "#343843",
        secondaryBg: "#24292D",
        midnightBlue: "#191970",
        grayBorder: "#383636",
        outlines: "#81a7e3",
        blueyGray: "#d5e0f0",
        anchorTag: "#d6a1f7",
        blushPink: "#ff868a",
        greyBlue: "#637387",
        violetBg: "#46424F",
        editIcon: "#000000",
        darkGray: "#B9B6B6",
        navItem: "#B9B6B6",
        selectedNavItem: "#FFFFFF",
        selectedGridItem: "#FFFFFF",
        selectedTab: "#FFFFFF",
        subText: "#FFFFFF",
        success: "#2EC998",
        helpText: "#FFFFFF",
        tooltipBg: "#706487",
        subHeading: "#FFFFFF",
        focusedInput: "#FFFFFF",
        folderBreadcrumbs: "#FFFFFF",
        pageTitleText: "#FFFFFF",
        accentText: "#FFFFFF",
        previewDialogHeading1: "#FFFFFF",
        previewDialogHeading2: "#FFFFFF",
        previewDialogText: "#FFFFFF",
        restoreBanner: "#4A5D7A",
        emailNotificationLabelError: "#C2ADFE",
        emailNotifcationMailIcon: "#C2ADFE",
        tinyMceFontColor: "#FFFFFF",
        tinyMceBgColor: "#343843",
        documentCaption: "#FFFFFF",
      },
    },
  },
});
