/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { HelpModel } from "../../../models/help/help";
import store from "@/store/index";
Vue.use(Vuex);

@Module({ name: "Help", namespaced: true, dynamic: true, store })
export class Help extends VuexModule {
  helpContent = {} as HelpModel;

  get getContent () {
    return this.helpContent.Content;
  }

  @Mutation
  SET_HELP_CONTENT (content: HelpModel) {
    this.helpContent = content;
  }

  @Action
  setHelpContent (content: HelpModel) {
    this.SET_HELP_CONTENT(content);
  }
}

export default getModule(Help);
