import axios from "axios";

class FavoriteService {
  async getFavoritesContent () {
    return await axios
      .get("api/favorites/content")
      .then(r => r.data)
      .catch(e => console.log(e));
  }

  async getFavorites () {
    return await axios
      .get("api/favorites")
      .then(r => r.data)
      .catch(e => console.log(e));
  }

  async setMaterialAsFavorite (material) {
    return await axios
      .post("api/favorites", material)
      .then(r => r.data)
      .catch(e => console.log(e));
  }

  async removeFavorite (contentId, contentTypeId) {
    return await axios
      .delete(`api/favorites/${contentId}/${contentTypeId}`)
      .then(r => r.data)
      .catch(e => console.log(e));
  }
}

export default new FavoriteService();
