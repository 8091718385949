/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Content, ContentList, Edited, PublishedMaterial, TaxonomyCodeToTitles } from "../../../models/content/content";
import { History } from "../../../models/history/history";
import FavoriteService from "../../../services/favoritesService";
import PatientModule from "../Patient/patient";
import FoldersModule from "../Folders/folders";
import createFrontEndId from "../../../utility/createFrontEndId";
import store from "@/store/index";
import mapContentToFavorite from "@/utility/favoriteMapper";
Vue.use(Vuex);

@Module({ name: "ContentModule", namespaced: true, dynamic: true, store })
export class ContentModule extends VuexModule {
  content = [] as Content[];
  selectedMaterials = {};
  publishedHistory = {};
  favorites = {};
  edited = [] as unknown as Edited;
  searchResults = [] as unknown as ContentList;
  isPageFavorites = false;
  displayedContentCount = 0;
  previewedMaterialIndex =  0;
  taxonomyCodeToTitles: TaxonomyCodeToTitles[] = [];

  get getContent () {
    return this.content;
  }

  get getPreviewedMaterialIndex () {
    return this.previewedMaterialIndex;
  }

  get getTaxonomyTitleCount () {
    return (taxonomyCode) => {
      const taxToTitles = this.taxonomyCodeToTitles.find(taxToTitles => taxToTitles.TaxonomyCode === taxonomyCode);
      let count = 0;
      if (taxToTitles) {
        count = taxToTitles.Titles
      }
      return count;
    };
  }

  get getselectedMaterials () {
    return this.selectedMaterials;
  }

  get getselectedMaterialsArray () {
    // eslint-disable-next-line
    const materialsArray: any = [];
    for (const m in this.selectedMaterials) materialsArray.push(this.selectedMaterials[m]);
    return materialsArray;
  }

  get getFavorites () {
    return this.favorites;
  }

  get getFavoritesLength () {
    return Object.entries(this.favorites).length;
  }

  get favoritesDisplayedContentCount () {
    return Object.keys(this.favorites).length;
  }

  get getEdited () {
    return this.edited;
  }

  // Get the ContentItem object from each Favorite.
  get getFavoritesContentArray () {
    const contentItems: ContentList[] = [];
    Object.keys(this.favorites).forEach(key => {
      if (this.favorites[key].ContentItem != null) {
        contentItems.push(this.favorites[key].ContentItem);
      }
    });
    contentItems.sort((a, b) => (a.Title < b.Title ? -1 : (a.Title > b.Title ? 1 : 0)));
    return contentItems;
  }

  // Return whether favorites content needs to be loaded. For performance
  // reasons, loading favorites content is not done during initial load.
  get needToLoadFavoriteContent () {
    const favoritesObject = this.favorites;
    // if there are no items in the favorites object, this could be a
    // saml/care connector launch, so return true to double check
    if (Object.keys(favoritesObject).length === 0) {
      return true;
    }
    // if there are any favorites in the favorites object
    // that we don't already have the content for, return true
    for (const key of Object.keys(favoritesObject)) {
      if (favoritesObject[key].ContentItem == null) {
        return true;
      }
    }
    return false;
  };

  @Mutation
  GET_CONTENT_BY_ID (contentId) {
    const materialContent = this.content.flatMap(c => c.ContentList.find(m => m.ContentId === contentId));
    return materialContent.length > 1 ? materialContent[0] : null;
  }

  @Mutation
  PUSH_CONTENT (content) {
    if (!this.content) this.content = [];

    const taxonomyContent = this.content.find(item => item.Taxonomies[0].Code === content.Taxonomies[0].Code);
    if (taxonomyContent) {
      // first empty, then push
      taxonomyContent.ContentList.length = 0;
      taxonomyContent.ContentList.push(...content.ContentList);
      taxonomyContent.TotalResults = content.TotalResults;
    } else {
      this.content.push(content);
    }
  }

  @Mutation
  CLEAR_CONTENT () {
    this.content = [];
  }

  @Mutation
  TOGGLE_FAVORITE ({ material, isFavorite, contentOrigin }) {
    const newFavorite = this.favorites;
    if (isFavorite) {
      const favoriteContent = mapContentToFavorite(material);
      favoriteContent.ContentItem = {...favoriteContent.ContentItem, ContentOrigin: contentOrigin};
      newFavorite[material.FrontEndId] = favoriteContent;
    } else {
      delete newFavorite[material.FrontEndId];
    }
    this.favorites = { ...newFavorite };
  }

  @Mutation
  TOGGLE_EDITED ({ contentId, taxonomyCode, isEdited, contentTypeId }) {
    if (taxonomyCode && taxonomyCode !== "Folders" && taxonomyCode !== "Favorites") {
      const contentItems = this.content.find((t: Content) => t.Taxonomies[0].Code === taxonomyCode);

      if (contentItems) {
        contentItems.ContentList.find(c => c.ContentId === contentId).IsInlineEdited = isEdited;
      }
    } else {
      let contentItem;
      for (let i = 0; i < this.content.length; i++) {
        const content = this.content[i];
        for (let j = 0; j < content.ContentList.length; j++) {
          const listItem = content.ContentList[j];
          if (listItem.ContentId === contentId) {
            contentItem = listItem;
            break;
          }
        }
        if (contentItem) {
          break;
        }
      }
      if (contentItem) {
        contentItem.IsInlineEdited = isEdited;
      }
    }

    // toggle the folder material if there is one, and update search items and favorites
    FoldersModule.toggleEdited({ contentId, isEdited });
    const searchItem = this.searchResults.find(c => c.ContentId === contentId);
    if (searchItem) {
      searchItem.IsInlineEdited = isEdited;
    }

    const favoritesValues: ContentList[] = Object.values(this.favorites);
    const favoriteMaterial: ContentList | undefined = favoritesValues.find((favorite: ContentList) => favorite.ContentId === contentId);
    if (favoriteMaterial) {
      favoriteMaterial.IsInlineEdited = isEdited;
    }

    if (isEdited) {
      store.commit("ContentModule/ADD_TO_EDITED", ({ contentId, contentTypeId }));
    } else {
      store.commit("ContentModule/REMOVE_FROM_EDITED", ({ contentId }));
    }
  }

  @Mutation
  ADD_TO_EDITED ({ contentId, contentTypeId }) {
    this.edited.push({ contentId, contentTypeId });
  }

  @Mutation
  REMOVE_FROM_EDITED ({ contentId }) {
    const index = this.edited.findIndex(editedItems => editedItems.contentId === contentId);
    this.edited.splice(index, 1);
  }

  @Mutation
  ADD_TO_SELECTED (material) {
    const selected = this.selectedMaterials;
    selected[material.FrontEndId] = material;
    this.selectedMaterials = { ...selected };
  }

  @Mutation
  REMOVE_FROM_SELECTED (material) {
    const selected = this.selectedMaterials;
    delete selected[material.FrontEndId];
    this.selectedMaterials = { ...selected };
  }

  @Mutation
  SET_SELECTED_MATERIALS (materials) {
    const newMaterials = materials.reduce((previousValue, currentValue) => {
      currentValue.FrontEndId = createFrontEndId(currentValue);
      if (!previousValue[currentValue.FrontEndId]) previousValue[currentValue.FrontEndId] = currentValue;
      return previousValue;
    }, {});

    this.selectedMaterials = newMaterials;
  }

  @Mutation
  CHANGE_LANGUAGE ({ material, language }) {
    material.LanguageCode = language.Code;
    material.Language = language;
    material.ContentId = language.ContentId;
    material.FrontEndId = createFrontEndId(material);

    if (!material.Taxonomy && this.isPageFavorites) return;

    if (!material.Taxonomy && !this.isPageFavorites) {
      this.searchResults.map(c => {
        if (c.ContentId === material.ContentId) {
          c.LanguageCode = language.Code;
          c.Language = language;
          c.ContentId = language.ContentId;
        }
      });
      return;
    }

    const content = this.content.find((t: Content) => t.Taxonomies[0].Code === material.Taxonomy.Code);

    if (content) {
      content.ContentList.map(c => {
        if (c.ContentId === material.ContentId) {
          c.LanguageCode = language.Code;
          c.Language = language;
          c.ContentId = language.ContentId;
        }
      });
    }
  }

  @Mutation
  SET_PUBLISHED_MATERIALS (materials) {
    if (!materials || materials.length === 0) return;
    materials.sort((a: PublishedMaterial, b: PublishedMaterial) => {
      return new Date(b.patientPrescribedDate).getTime() - new Date(a.patientPrescribedDate).getTime();
    });
    const publishedMaterials = materials.reduce((previousValue, currentValue) => {
      currentValue.FrontEndId = createFrontEndId(currentValue);
      if (!previousValue[currentValue.FrontEndId]) previousValue[currentValue.FrontEndId] = currentValue;
      return previousValue;
    }, {});

    this.publishedHistory = publishedMaterials;
  }

  @Mutation
  SET_FAVORITES (favorites) {
    const favoritesObject = favorites.reduce((previousValue, currentValue) => {
      currentValue.FrontEndId = createFrontEndId(currentValue);
      if (!previousValue[currentValue.FrontEndId]) previousValue[currentValue.FrontEndId] = currentValue;
      for (let i = 0; i < this.edited.length; i++) {
        if (this.edited[i].contentId === currentValue.ContentId) {
          currentValue.IsInlineEdited = true;
          break;
        }
      }
      return previousValue;
    }, {});

    this.favorites = favoritesObject;
  }

  @Mutation
  SET_EDITED (edited) {
    this.edited = edited;
  }

  @Mutation
  SET_SEARCH_RESULTS (contentList) {
    contentList.forEach(c => {
      c.FrontEndId = createFrontEndId(c);
      for (let i = 0; i < this.edited.length; i++) {
        if (this.edited[i].contentId === c.ContentId) {
          c.IsInlineEdited = true;
          break;
        }
      }
    });

    this.searchResults = contentList;
  }

  @Mutation
  SET_PAGE (flag) {
    this.isPageFavorites = flag;
  }

  @Action
  updatePublishedHistory (contentToUpdate: History[]) {
    this.UPDATE_PUBLISHED_HISTORY_FOR_RECALLS(contentToUpdate);
  }

  @Mutation
  UPDATE_PUBLISHED_HISTORY_FOR_RECALLS (contentToUpdate: History[]) {
    contentToUpdate.forEach(item => {
      const frontEndId = createFrontEndId(item);
      const content = this.publishedHistory[frontEndId];
      content.isRecalled = true;
      content.dateRecalled = new Date().toISOString();
      content.patientPrescribedType = "Recalled";
    });
  }

  @Action
  clearContent () {
    this.CLEAR_CONTENT();
  }

  @Action
  clearTitleCounts() {
    this.CLEAR_TITLE_COUNTS();
  }

  @Mutation
  CLEAR_TITLE_COUNTS() {
    this.taxonomyCodeToTitles = [];
  }
  @Action
  pushTitleCounts (titleCountsForTaxonomy) {
    this.PUSH_TITLE_COUNTS(titleCountsForTaxonomy);
  }

  @Mutation
  PUSH_TITLE_COUNTS (titleCountsForTaxonomy) {
    const { TaxonomyCode, Titles } = titleCountsForTaxonomy;
    const index = this.taxonomyCodeToTitles.findIndex(t => t.TaxonomyCode === TaxonomyCode);
    if (index !== -1) {
      this.taxonomyCodeToTitles[index].Titles = Titles;
    } else {
      this.taxonomyCodeToTitles.push({ TaxonomyCode, Titles });
    }
  }

  @Action
  pushContent (content) {
    if (!content) return;
    if (!content.ContentList) content.ContentList = [];

    content.ContentList.forEach(m => {
      const preferredLanguage = PatientModule.patient.PreferredLanguageCode;
      const isInLanguages = m.OtherLanguages.findIndex(l => l.Code === preferredLanguage) > -1;
      if (m.LanguageCode !== preferredLanguage && isInLanguages) m.LanguageCode = preferredLanguage;
      const isEdited = this.edited.findIndex(c => c.contentId === m.ContentId) > -1;
      if (isEdited) m.IsInlineEdited = true;
      m.FrontEndId = createFrontEndId(m);
    });
    this.PUSH_CONTENT(content);
    this.PUSH_TITLE_COUNTS({ TaxonomyCode: content.Taxonomies[0].Codings[0].Code, Titles: content.TotalResults });
  }

  @Action
  updateFavorites (favorites) {
    this.SET_FAVORITES(favorites);
  }

  @Action
  updateFavoritesContent (favoritesContent) {
    favoritesContent.forEach(f => { f.FrontEndId = createFrontEndId(f); });
    const favorites = favoritesContent.map(f => mapContentToFavorite(f));
    this.SET_FAVORITES(favorites);
  }

  @Action
  setPublishedMaterials (materials) {
    this.SET_PUBLISHED_MATERIALS(materials as PublishedMaterial[]);
  }

  @Action
  async toggleFavorite ({ material, contentTypeId, isFavorite }) {
    const response = isFavorite
      ? await FavoriteService.setMaterialAsFavorite({ ContentId: material.ContentId, contentTypeId })
      : await FavoriteService.removeFavorite(material.ContentId, contentTypeId);

    if (!response) return;

    this.TOGGLE_FAVORITE({ material, isFavorite, contentOrigin: response.ContentOrigin });
  }

  @Action
  updateEdited (edited) {
    if (edited !== null) {
      this.SET_EDITED(edited);
    }
  }

  @Action
  toggleEdited (data) {
    this.TOGGLE_EDITED(data);
  }

  @Action
  async addToSelected (material) {
    this.ADD_TO_SELECTED(material);
  }

  @Action
  removeFromSelected (material) {
    this.REMOVE_FROM_SELECTED(material);
  }

  @Action
  updateSelectedMaterials (materials) {
    this.SET_SELECTED_MATERIALS(materials);
  }

  @Action
  updateDisplayedContentCount (count: number) {
    this.SET_DISPLAYED_CONTENT_COUNT(count);
  }

  @Mutation
  SET_DISPLAYED_CONTENT_COUNT (count: number) {
    this.displayedContentCount = count;
  }

  @Action
  changeLanguage ({ material, language }) {
    this.CHANGE_LANGUAGE({ material, language });
  }

  @Action
  async updateSearchResults (contentList) {
    this.SET_SEARCH_RESULTS(contentList);
  }

  @Action
  setPage (flag) {
    this.SET_PAGE(flag);
  }

  @Action
  setPreviewedMaterialIndex (index: number) {
    this.SET_PREVIEWED_MATERIAL_INDEX(index);
  }

  @Mutation
  SET_PREVIEWED_MATERIAL_INDEX (index: number) {
    this.previewedMaterialIndex = index;
}

  @Action
  getContentById (contentId) {
    return this.GET_CONTENT_BY_ID(contentId);
  }
}

export default getModule(ContentModule);
