/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store/index";
import user from "../User/user";
Vue.use(Vuex);

@Module({ name: "TableSettings", namespaced: true, dynamic: true, store })
class TableSettings extends VuexModule {
  pageNumber = 1;
  listRowsPerPage = [10, 25, 50, 100];
  gridRowsPerPage = [9, 21, 45, 99];
  rowsPerPage = this.listRowsPerPage[1];
  view = "list";
  viewingFolders = false;
  viewingHistory = false;
  viewingFavorites = false;
  footerHeight = 0;

  get getRowsPerPageType (): number[] {
    let rowValues: number[] = [];
    switch (this.view) {
      case "list":
        rowValues = this.listRowsPerPage;
        break;
      case "grid":
        rowValues = this.gridRowsPerPage;
        break;
    }
    return rowValues;
  }

  get getRowsPerPage (): number {
    let rowValues = 0;
    if (user.isGridView) {
      rowValues = user.getUserSettings?.userSettings?.CardsPerPage ?? this.gridRowsPerPage[1];
      rowValues = this.gridRowsPerPage.indexOf(rowValues) === -1 ? this.gridRowsPerPage[1] : rowValues;
    } else {
      rowValues = user.getUserSettings?.userSettings?.RowsPerPage ?? this.listRowsPerPage[1];
      rowValues = this.listRowsPerPage.indexOf(rowValues) === -1 ? this.listRowsPerPage[1] : rowValues;
    }
    return rowValues;
  }

  @Action
  updateSelectedRowsPerPage (rowsPerPage: number) {
    this.SET_ROWS_PER_PAGE(rowsPerPage);
  }

  @Action
  updateFooterHeight (height: number) {
    this.SET_FOOTER_HEIGHT(height);
  }

  @Mutation
  SET_FOOTER_HEIGHT (height: number) {
    this.footerHeight = height;
  }

  @Action
  setTableView (view: string) {
    this.SET_TABLE_VIEW(view);
  }

  @Mutation
  SET_TABLE_VIEW (view: string) {
    this.view = view;
  }

  @Mutation
  SET_ROWS_PER_PAGE (rowsPerPage: number) {
    this.rowsPerPage = rowsPerPage;
  }

  @Action
  updatePageNumber (pageNumber: number) {
    this.SET_PAGE_NUMBER(pageNumber);
  }

  @Mutation
  SET_PAGE_NUMBER (pageNumber: number) {
    this.pageNumber = pageNumber;
  }

  @Action
  updateFoldersView (viewingFolders: boolean) {
    this.SET_VIEWING_FOLDERS(viewingFolders);
  }

  @Mutation
  SET_VIEWING_FOLDERS (viewingFolders: boolean) {
    this.viewingFolders = viewingFolders;
  }

  @Action
  updateHistoryView (viewingHistory: boolean) {
    this.SET_VIEWING_HISTORY(viewingHistory);
  }

  @Mutation
  SET_VIEWING_HISTORY (viewingHistory: boolean) {
    this.viewingHistory = viewingHistory;
  }

  @Action
  updateFavoritesView (viewingFavorites: boolean) {
    this.SET_VIEWING_FAVORITES(viewingFavorites);
  }

  @Mutation
  SET_VIEWING_FAVORITES (viewingFavorites: boolean) {
    this.viewingFavorites = viewingFavorites;
  }
}

export default getModule(TableSettings);
