/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store/index";
Vue.use(Vuex);

@Module({ name: "IdleTime", namespaced: true, dynamic: true, store })
class IdleTime extends VuexModule {
  idleTimeBeforeWarningMilliseconds = 10000;
  warningTimeSeconds = 20;
  minutesOfWarnignRemaining = 60;
  secondsOfWarningRemaining = 42;
  showDialog = false;
  timerId = 0;

  get getShowDialog () {
    return true;
  }

  get getWarningTimeSeconds () {
    return this.warningTimeSeconds;
  }

  @Action
  watchIdleTime ({ expirationTimeout, expirationNoticeWindow }) {
    this.SET_IDLE_TIME_BEFORE_WARNING_MILLISECONDS((expirationTimeout - expirationNoticeWindow) * 60 * 1000);
    this.SET_WARNING_TIME_SECONDS(expirationNoticeWindow * 60);
    this.startIdleTimer();
  }

  @Action
  startIdleWarning () {
    this.SET_TIMER_ID(0);
    this.SET_SHOW_DIALOG(true);
  };

  @Action
  onActivity () {
    if (!this.showDialog) {
      if (this.timerId) {
        clearTimeout(this.timerId);
        this.SET_TIMER_ID(0);
      }
      this.startIdleTimer();
    }
  };

  @Action
  startIdleTimer () {
    if (!this.timerId) {
      const id = setTimeout(this.startIdleWarning, this.idleTimeBeforeWarningMilliseconds);
      this.SET_TIMER_ID(id);
      window.addEventListener("click", this.onActivity, false);
      window.addEventListener("keydown", this.onActivity, false);
      window.addEventListener("scroll", this.onActivity, false);
      window.addEventListener("wheel", this.onActivity, false);
    }
  };

  @Action
  RestartIdleWatch () {
    this.SET_SHOW_DIALOG(false);
    this.startIdleTimer();
  }

  @Mutation
  SET_IDLE_TIME_BEFORE_WARNING_MILLISECONDS (numMinutes: number) {
    this.idleTimeBeforeWarningMilliseconds = numMinutes;
  }

  @Mutation
  SET_WARNING_TIME_SECONDS (numMinutes: number) {
    this.warningTimeSeconds = numMinutes;
  }

  @Mutation
  SET_TIMER_ID (id: number) {
    this.timerId = id;
  }

  @Mutation
  SET_SHOW_DIALOG (flag: boolean) {
    this.showDialog = flag;
  }

  @Action
  setShowDialog (flag: boolean) {
    this.SET_SHOW_DIALOG(flag);
  }
}

export default getModule(IdleTime);
