import { ref } from "vue";

const useFTwelve = ref(false);
const appendLink = (href: string) => {
  const link = document.createElement("link");
  link.setAttribute("rel", "stylesheet");
  link.setAttribute("href", href);
  document.head.appendChild(link);
};

const appendScript = (src: string) => {
  const script = document.createElement("script");
  script.setAttribute("src", src);
  document.body.appendChild(script);
};

const loadCerner = () => {
  appendLink("/css/cerner/cerner-smart-embeddable-lib-1.4.0.min.css");
  appendScript("/libs/cerner/cerner-smart-embeddable-lib-1.4.0.min.js");
  appendScript("/libs/cerner/polyfill.min.js");
  if (useFTwelve.value) {
    appendScript("/libs/cerner/f-twelve.umd.js");
    appendLink("/css/cerner/f-twelve.css");
  }
};

const useCerner = () => {
  return { useFTwelve, loadCerner };
};

export { useCerner };
