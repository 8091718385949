import { ref } from "vue";

const unLoading = ref(false);

const purge = (d) => {
  let a = d.attributes;
  if (a) {
    for (let i = a.length - 1; i >= 0; i -= 1) {
      const n = a[i].name;
      if (typeof d[n] === "function") {
        d[n] = null;
      }
    }
  }
  a = d.childNodes;
  if (a) {
    const l = a.length;
    for (let i = 0; i < l; i += 1) {
      purge(d.childNodes[i]);
    }
  }
};
const purgeApp = () => {
  purge(document.getElementById("app"));
  unLoading.value = true;
  window.removeEventListener("beforeunload", purgeApp);
};

const usePurgeApp = () => {
  return { unLoading, purgeApp };
};

export { usePurgeApp };
