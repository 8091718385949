import axios from "axios";

class PatientService {
  async getPatientInfo () {
    return await axios
      .get("api/patient")
      .then(r => r.data)
      .catch(e => console.log(e));
  }
}

export default new PatientService();
