/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import {
  User,
  CategoryTab,
  DisplaySettings,
  ActivityHistory,
  CategoryTabs,
  ContentTypeFilter,
  AvsCheckbox,
  Modals,
  PublishButton,
  PrintButton,
  Branding,
  Labels,
} from "../../../models/user/user";
import UserService from "../../../services/userService";
import tableSettings from "../TableSettings/tableSettings";
import store from "@/store/index";
Vue.use(Vuex);

@Module({ name: "UserSettings", namespaced: true, dynamic: true, store })
class UserSettings extends VuexModule {
  user = {} as User;
  isPatientPortalEnabled = false;
  isDocumentEditingEnabled = false;

  get displaySettings () {
    if (this.user && this.user.displaySettings) {
      return this.user.displaySettings;
    } else {
      return {
        darkModeEnabled: false,
        filtersOpen: false,
        filtersSort: null,
        branding: {} as Branding,
        categoryTabs: {
          tabs: [{}],
        } as CategoryTabs,
        hiddenMenuIconNames: [],
        labels: {} as Labels,
        landingRedirect: {},
        printButton: {} as PrintButton,
        publishButton: {} as PublishButton,
        cartItemLimit: {},
        modals: {} as Modals,
        help: {},
        activityHistory: {} as ActivityHistory,
        contentTypeFilter: {} as ContentTypeFilter,
        avsCheckbox: {} as AvsCheckbox,
      } as DisplaySettings;
    }
  }

  get getUserSettings () {
    return this.user;
  }

  get isGridView () {
    const isGrid = this.user?.userSettings?.SearchLayoutType === "Grid";
    tableSettings.setTableView(isGrid ? "grid" : "list");
    return isGrid;
  }

  get getCompanyLabel () {
    return this.displaySettings.branding.companyLabel;
  }

  get getProductLabel () {
    return this.displaySettings.branding.productLabel;
  }

  get getFirstCategory () {
    const tab = this.getCategoryTabs[0] as CategoryTab;
    if (!tab) {
      return {
        id: 0,
        label: "",
      } as CategoryTab;
    } else {
      return tab;
    }
  }

  get getCategoryTabs () {
    return this.displaySettings.categoryTabs.tabs.filter(
      tab => tab.visibility === 1
    );
  }

  get getPatientPortalSettings () {
    return this.isPatientPortalEnabled;
  }

  get getDocumentEditingEnabled () {
    return this.isDocumentEditingEnabled;
  }

  @Mutation
  SET_USER_SETTINGS (user: User) {
    this.user = user;
  }

  @Mutation
  SET_PATIENT_PORTAL_SETTINGS (statement: boolean) {
    this.isPatientPortalEnabled = statement;
  }

  @Mutation
  SET_DOCUMENT_EDITING_ENABLED (statement: boolean) {
    this.isDocumentEditingEnabled = statement;
  }

  @Mutation
  UPDATE_SEARCH_LAYOUT_TYPE (isGridView: boolean) {
    if (this.user?.userSettings?.SearchLayoutType) {
      this.user.userSettings.SearchLayoutType = isGridView ? "Grid" : "List";
    }
  }

  @Mutation
  UPDATE_ROWS_PER_PAGE (rowsPerPage: number) {
    if (this.user?.userSettings) {
      this.user.userSettings.RowsPerPage = rowsPerPage;
    }
  }

  @Mutation
  UPDATE_CARDS_PER_PAGE (cardsPerPage: number) {
    if (this.user?.userSettings) {
      this.user.userSettings.CardsPerPage = cardsPerPage;
    }
  }

  @Mutation
  UPDATE_DARK_MODE_ENABLED (darkModeEnabled: boolean) {
    if (this.user?.userSettings) {
      this.user.userSettings.DarkModeEnabled = darkModeEnabled;
    }
  }

  @Mutation
  UPDATE_FILTERS_OPEN (filtersOpen: boolean) {
    if (this.user?.userSettings) {
      this.user.userSettings.FiltersOpen = filtersOpen;
    }
  }

  @Mutation
  UPDATE_FILTERS_SORT (filtersSort: number) {
    if (this.user?.userSettings) {
      this.user.userSettings.FiltersSort = filtersSort;
    }
  }

  @Action
  setUserSettings (settings: User) {
    this.SET_USER_SETTINGS(settings);
  }

  @Action
  setUserSettingsCartItemLimit (cartLimit: number) {
    const user = this.user;
    user.displaySettings.cartItemLimit = cartLimit;
    this.SET_USER_SETTINGS(user);
  }

  @Action
  setPatientPortalSettings (statement: boolean) {
    this.SET_PATIENT_PORTAL_SETTINGS(statement);
  }

  @Action
  setDocumentEditingEnabled (statement: boolean) {
    this.SET_DOCUMENT_EDITING_ENABLED(statement);
  }

  @Action
  async updateSearchLayoutType (isGridView: boolean) {
    this.UPDATE_SEARCH_LAYOUT_TYPE(isGridView);
    await UserService.updateUserSettings(this.user?.userSettings);
  }

  @Action
  async updateRowsPerPage (rowsPerPage: number) {
    this.UPDATE_ROWS_PER_PAGE(rowsPerPage);
    await UserService.updateUserSettings(this.user?.userSettings);
  }

  @Action
  async updateCardsPerPage (cardsPerPage: number) {
    this.UPDATE_CARDS_PER_PAGE(cardsPerPage);
    await UserService.updateUserSettings(this.user?.userSettings);
  }

  @Action
  async updateDarkModeEnabled (darkModeEnabled: boolean) {
    this.UPDATE_DARK_MODE_ENABLED(darkModeEnabled);
    await UserService.updateUserSettings(this.user?.userSettings);
  }

  @Action
  async updateFiltersOpen (filtersOpen: boolean) {
    this.UPDATE_FILTERS_OPEN(filtersOpen);
    await UserService.updateUserSettings(this.user?.userSettings);
  }

  @Action
  async updateFiltersSort (filtersSort: number) {
    this.UPDATE_FILTERS_SORT(filtersSort);
    await UserService.updateUserSettings(this.user?.userSettings);
  }
}

export default getModule(UserSettings);
