/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { FoldersModel } from "../../../models/folders/folders";
import { ContentList } from "../../../models/content/content";
import createFrontEndId from "../../../utility/createFrontEndId";
import ContentModule from "../Content/content";
import store from "@/store/index";
Vue.use(Vuex);

@Module({ name: "Folders", namespaced: true, dynamic: true, store })
class Folders extends VuexModule {
  folders = [] as FoldersModel[];
  folderMaterials: { [key: string]: ContentList} = {};

  get getFirstLevelFolders () {
    return this.folders.filter(x => !x.ParentFolderId);
  }

  get getFolderMaterials () {
    return this.folderMaterials;
  }

  get getCountOfMaterials () {
    return Object.keys(this.folderMaterials).length;
  }

  @Mutation
  SET_FOLDERS (data: FoldersModel[]) {
    this.folders = data;
  }

  @Action
  setFolders (data: FoldersModel[]) {
    this.SET_FOLDERS(data.sort((a, b) => a.DisplayName.localeCompare(b.DisplayName)));
  }

  @Mutation
  SET_MATERIALS (data) {
    const materials: { [key: string]: ContentList } = data.reduce((previousValue, currentValue) => {
      currentValue.FrontEndId = createFrontEndId(currentValue);
      if (!previousValue[currentValue.FrontEndId]) previousValue[currentValue.FrontEndId] = currentValue;
      return previousValue;
    }, {});
    // now for each material, check if they are in the edited array in the content module
    const edited = ContentModule.getEdited;
    Object.values(materials).forEach(contentItem => {
      for (let i = 0; i < edited.length; i++) {
        if (edited[i].contentId === contentItem.ContentId) {
          contentItem.IsInlineEdited = true;
          break;
        }
      }
    });
    this.folderMaterials = materials;
  }

  @Action
  updateMaterials (data) {
    this.SET_MATERIALS(data);
  }

  @Action
  toggleEdited (data) {
    this.TOGGLE_EDITED(data);
  }

  @Mutation
  TOGGLE_EDITED ({ contentId, isEdited }) {
    const contentList: ContentList[] = Object.values(this.folderMaterials);
    const content: ContentList | undefined = contentList.find((t: ContentList) => t.ContentId === contentId);
    if (content) {
      content.IsInlineEdited = isEdited;
    }
  }
}

export default getModule(Folders);
