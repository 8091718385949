/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { EducationCartModel } from "../../../models/educationCart/educationCart";
import EducationCartService from "../../../services/educationCartService";
import store from "@/store/index";
Vue.use(Vuex);

@Module({ name: "EducationCart", namespaced: true, dynamic: true, store })
class EducationCart extends VuexModule {
  educationCart = {} as EducationCartModel;

  get getEducationCart () {
    return this.educationCart;
  }

  get getAVS () {
    return this.educationCart.AddToAvs;
  }

  get getPersonalization () {
    return this.educationCart.AddPatientName;
  }

  get getVideoTranscript () {
    return this.educationCart.VideoTranscript;
  }

  get getPrintSize () {
    return this.educationCart.PrintSize;
  }

  @Mutation
  SET_CART (content: EducationCartModel) {
    this.educationCart = content;
  }

  @Action
  setCart (content: EducationCartModel) {
    this.SET_CART(content);
  }

  @Mutation
  UPDATE_CART ({ settingName, value }) {
    this.educationCart[settingName] = value;
  }

  @Action
  async updateCart ({ settingName, value }) {
    this.UPDATE_CART({ settingName, value });
    await EducationCartService.updateEducationCart(this.educationCart);
  }
}

export default getModule(EducationCart);
