import axios from "axios";

class PublishedHistory {
  async getPublishedHistory () {
    return await axios
      .get("api/patient/publishedHistory")
      .then(r => r.data)
      .catch(e => console.log(e));
  }
}

export default new PublishedHistory();
