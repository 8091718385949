/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { History } from "../../../models/history/history";
import store from "@/store/index";
Vue.use(Vuex);

@Module({ name: "ActivityHistory", namespaced: true, dynamic: true, store })
class ActivityHistory extends VuexModule {
  historyContent: History[] = [];

  get getHistory () {
    return this.historyContent.sort((a, b) => {
      // if an item has recall date, compare by that, otherwise use regular dates
      const aDate = a.DateRecalled ? new Date(a.DateRecalled) : new Date(a.Date);
      const bDate = b.DateRecalled ? new Date(b.DateRecalled) : new Date(b.Date);
      return bDate.getTime() - aDate.getTime();
    });
  }

  get displayedContentCount () {
    return Object.keys(this.historyContent).length;
  }

  @Mutation
  SET_HISTORY (content) {
    this.historyContent = content;
  }

  @Action
  setHistory (content) {
    this.SET_HISTORY(content);
  }

  @Action
  updateRecalledItems (recalledItems: History[]) {
    this.UPDATE_RECALLED_ITEMS(recalledItems);
  }

  @Mutation
  UPDATE_RECALLED_ITEMS (recalledItems: History[]) {
    recalledItems.forEach(item => {
      const index = this.historyContent.findIndex(el => el.UniqueDocumentId === item.UniqueDocumentId);
      this.historyContent[index].IsRecalled = true;
      this.historyContent[index].DateRecalled = new Date().toISOString();
    });
  }
}

export default getModule(ActivityHistory);
