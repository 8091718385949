import axios from "axios";

class UIConfigurationService {
  async information () {
    return await axios
      .get("api/uiConfiguration/uiInformation")
      .then(r => r.data)
      .catch(e => console.log(e));
  };
};

export default new UIConfigurationService();
