var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{class:{'fullWidth': true, 'sidebarOpen': _vm.isFilterOpen, 'primaryBg': true},attrs:{"fliud":""}},[_c('v-row',{class:{'mt-12': !_vm.apisLoading, 'mt-0': _vm.apisLoading, 'mx-2': true, 'mb-2': true},attrs:{"id":"searchTab","data-qaid":"recommendedContentHeader"}},[_c('div',{staticClass:"mb-2",class:{ displayBlock: _vm.$vuetify.breakpoint.smAndDown },attrs:{"tabindex":"0","name":"Page Title"}},[_c('p',{staticClass:"pageTitle pageTitleText--text ma-0"},[_vm._v(_vm._s(!_vm.areSearchResultsVisible ? "Recommended Content" : "Search Results"))])]),_c('div',{staticClass:"d-flex search-card align-center text--greyBlue",class:{
        'ml-auto': _vm.$vuetify.breakpoint.smAndUp,
        smallSearchCard: _vm.$vuetify.breakpoint.smAndDown,
        wideSearchCard: _vm.$vuetify.breakpoint.mdAndUp,
        searchContainer: true,
      },attrs:{"data-qaid":"searchBoxDiv"}},[_c('v-autocomplete',{ref:"searchBar",staticClass:"mr-2",attrs:{"id":"searchTermsBar","data-qaid":"searchInput","items":_vm.filteredItems,"search-input":_vm.searchTerm,"append-icon":_vm.searching ? 'mdi-refresh' : 'mdi-magnify',"solo":"","dense":"","clearable":"","hide-no-data":"","hide-details":"","hide-selected":"","label":_vm.loadingTerms ? 'Retrieving Search Terms' : 'Search',"loading":_vm.loadingTerms,"menu-props":{ maxHeight: _vm.smallHeight ? 200 : 600 },"color":_vm.$vuetify.theme.dark ? 'white' : 'trueBlue',"role":"SearchBox","name":"Search Bar","background-color":_vm.$vuetify.theme.dark ? 'violetBg' : '',"aria-label":"Content Search Bar"},on:{"update:searchInput":function($event){_vm.searchTerm=$event},"update:search-input":function($event){_vm.searchTerm=$event},"click:clear":_vm.clearTypedTermsEvent,"click:append":_vm.searchEvent,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.searchEvent.apply(null, arguments)},"blur":_vm.blurEvent,"focus":_vm.clearTypedTermsEvent,"change":_vm.changeEvent,"update:list-index":_vm.updateAutocompleteMenuListIndex},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),(!_vm.isFilterOpen)?_c('v-btn',{staticClass:"mr-2",class:{
          'ml-auto': _vm.$vuetify.breakpoint.xsOnly,
        },attrs:{"data-qaid":"button_sideFilter","icon":"","color":_vm.$vuetify.theme.dark ? 'white' : 'secondary',"role":"button","aria-label":"Sort and Filter","name":"Sort and Filter"},on:{"click":function($event){$event.stopPropagation();return _vm.openFilter.apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-filter-variant")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }