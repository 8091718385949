/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { PatientModel } from "../../../models/patient/patient";
import PrintShare from "../PrintShare/printShare";
import UserModule from "../User/user";
import store from "@/store/index";
Vue.use(Vuex);

@Module({ name: "Patient", namespaced: true, dynamic: true, store })
class Patient extends VuexModule {
  patient = {} as PatientModel;
  
  // flag required  for style: blue underline for home icon
  isPatientEncounterVisible = false;

  get getPatient () {
    return this.patient;
  }

  get getAgeGroupId () {
    return this.patient.AgeGroupId;
  }

  get getGender () {
    return this.patient.Gender;
  }

  get getEmail () {
    return this.patient.EmailAddress;
  }

  get getAddress () {
    return this.patient.Address;
  }

  @Mutation
  SET_PATIENT (patientInfo: PatientModel) {
    this.patient = patientInfo;
  }

  @Mutation
  SET_EMAIL (email: string) {
    this.patient.EmailAddress = email;
  }

  @Action
  setPatient (patientInfo: PatientModel) {
    this.SET_PATIENT(patientInfo);
    if (UserModule.isPatientPortalEnabled && this.getEmail) {
      PrintShare.saveState({ triggerEmailNotification: false });
    }
  }

  @Action
  setEmail (email: string) {
    this.SET_EMAIL(email);
    PrintShare.saveState({ triggerEmailNotification: false });
  }
  
  @Action
  setIsPatientEncounterVisible (visible: boolean) {
    this.SET_IS_PATIENTENCOUNTER_VISIBLE(visible);
  }

  @Mutation
  SET_IS_PATIENTENCOUNTER_VISIBLE (visible: boolean) {
    this.isPatientEncounterVisible = visible;
  }
}

export default getModule(Patient);
