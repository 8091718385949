export default function PolyfillClosest () {
  if (!Element.prototype.closest) {
    if (!Element.prototype.matches) {
      // @ts-expect-error
      Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
    }
    Element.prototype.closest = function (s) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const el = this;
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let ancestor: Element| null = this;
      if (!document.documentElement.contains(el)) return null;
      do {
        if (ancestor.matches(s)) return ancestor;
        ancestor = ancestor.parentElement;
      } while (ancestor !== null);
      return null;
    };
  }
}
