const appendNewRelic = (appId: string, agentId: string, tenantId: string, kofhirSessionId: string) => {
  // only append if it hasn't been appended already
  if (!document.getElementById("new-relic")) {
    const script = document.createElement("script");
    script.id = "new-relic";
    script.setAttribute("src", "/libs/nr-loader-full.min.js");
    script.setAttribute("data-appId", appId);
    script.setAttribute("data-agentId", agentId);
    script.setAttribute("data-tenantId", tenantId);
    script.setAttribute("data-kofhirSessionId", kofhirSessionId);

    const head = document.getElementById("document-head");
    const favicon = document.getElementById("favicon");
    if (head && favicon) {
      head.insertBefore(script, favicon);
    }
  }
};

const activateNewRelic = (query) => {
  const nr = query.nr as string;
  if (nr) {
    const appId = query.nrAppId as string;
    const agentId = query.nrAgentId as string;
    const tenantId = query.tenantId as string;
    const kofhirSessionId = query.kofsid as string;
    appendNewRelic(appId, agentId, tenantId, kofhirSessionId);
  }
};

export default activateNewRelic;
